.debug .song-card,
.debug .topbar {
  opacity: 1 !important;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
}
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: none;
}
*.hidden {
  display: none !important;
}
body {
  --bg-color: #fff;
  --text-color: #222;
  --logo-note-color: #fff;
  --note-color: #000;
  --notation-line-color: #c8c8c8;
  --notation-bg-color: #f6f6f6;
  --notation-bg-hover-color: #ececec;
  --notation-bg-active-color: #faf0f3;
  --primary-color-regular: #ff5d8e;
  --primary-color-light: #ffbbd0;
  --topbar-color: rgba(255,255,255,0.7);
  --notation-frame-border: none;
  --notation-number: rgba(0,0,0,0.7);
  --notation-number-active: #824759;
  --scrollbar-color: #d3d3d3;
  --hjFeedbackAccentColor: #fff !important;
}
body.dark-theme {
  --bg-color: #1f1b2b;
  --text-color: #fff;
  --note-color: #fff;
  --logo-note-color: #fff;
  --notation-line-color: #352c38;
  --notation-bg-color: #1d1a29;
  --notation-bg-hover-color: #181622;
  --notation-bg-active-color: #261b2b;
  --primary-color-regular: #ff705d;
  --primary-color-light: #ffb0a6;
  --topbar-color: rgba(31,27,43,0.7);
  --notation-frame-border: 1px solid var(--notation-line-color);
  --notation-number: rgba(255,255,255,0.5);
  --notation-number-active: #ffbcd1;
  --scrollbar-color: #100d1b;
}
html,
body {
  background: var(--bg-color);
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  font-weight: 400;
}
.container {
  position: relative;
  margin: 0 auto;
}
@media (min-width: 640px) {
  .container {
    width: 80%;
    max-width: 900px;
  }
}
@media (max-width: 479px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 640px) {
  .song-list {
    margin-top: 40px;
  }
}
.preload .song-card {
  opacity: 0;
}
.song-card {
  position: relative;
  margin: 0 auto;
  display: block;
  padding-top: 40px;
}
@media (min-width: 640px) {
  .song-card {
    width: 80%;
    max-width: 900px;
  }
}
@media (max-width: 479px) {
  .song-card {
    width: 100%;
  }
}
.song-name {
  position: relative;
  left: 24px;
  font-size: 20px;
  width: 90%;
}
.btn-play {
  font-weight: 700;
  position: absolute;
  top: 37px;
  right: 16px;
  height: 24px;
  width: 74px;
  background: var(--primary-color-regular);
  color: #fff;
  border-radius: 40px;
  border: none;
}
.measure {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 0px 88px;
  grid-template-rows: 0px 88px;
}
.detailed .measure {
  -ms-grid-rows: 20px 88px;
  grid-template-rows: 20px 88px;
}
.measure.two-four {
  -ms-grid-columns: (1fr)[8];
  grid-template-columns: repeat(8, 1fr);
}
.measure.three-four {
  -ms-grid-columns: (1fr)[12];
  grid-template-columns: repeat(12, 1fr);
}
.measure.four-four {
  -ms-grid-columns: (1fr)[16];
  grid-template-columns: repeat(16, 1fr);
}
.measure.six-eight {
  -ms-grid-columns: (1fr)[24];
  grid-template-columns: repeat(24, 1fr);
}
.measure.nine-eight {
  -ms-grid-columns: (1fr)[18];
  grid-template-columns: repeat(18, 1fr);
}
.measure.twelve-eight {
  -ms-grid-columns: (1fr)[24];
  grid-template-columns: repeat(24, 1fr);
}
.measure.five-four {
  -ms-grid-columns: (1fr)[10];
  grid-template-columns: repeat(10, 1fr);
}
.measure.seven-four {
  -ms-grid-columns: (1fr)[14];
  grid-template-columns: repeat(14, 1fr);
}
.one-bar {
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
}
.two-bars {
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
}
.three-bars {
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
}
.four-bars {
  -ms-grid-columns: (1fr)[8];
  grid-template-columns: repeat(8, 1fr);
}
.notation-frame {
  display: -ms-grid;
  display: grid;
  position: relative;
  margin-top: 10px;
  width: 100%;
  background: var(--notation-bg-color);
}
.notation-frame .MuiButton-label {
  padding: 0 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 640px) {
  .notation-frame {
    border: var(--notation-frame-border);
  }
}
@media (max-width: 479px) {
  .notation-frame {
    border-radius: 0;
    border-top: var(--notation-frame-border);
    border-bottom: var(--notation-frame-border);
  }
}
.notation-frame:hover {
  cursor: pointer;
  background: var(--notation-bg-hover-color);
}
.notation-frame.active {
  background: var(--notation-bg-active-color);
}
.notation-frame.active:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid var(--primary-color-regular);
}
@media (min-width: 640px) {
  .notation-frame.active:after {
    border-radius: 5px;
  }
}
@media (max-width: 639px) {
  .notation-frame.active:after {
    border-radius: 0px;
    border-right: none;
    border-left: none;
  }
}
@media (max-width: 639px) {
  .notation-frame {
    border-radius: 0;
  }
}
@media (min-width: 640px) {
  .notation-frame {
    border-radius: 10px;
  }
}
.notation-frame .highlighted {
  background: #faf0f3;
}
.notation-frame .highlighted :after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  border: 2px solid var(--primary-color-regular);
}
.notation-note {
  position: relative;
}
.notation-number {
  font-family: 'Poppins', sans-serif;
  opacity: 0;
  text-align: center;
  color: var(--notation-number);
  font-size: 12px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}
.active .notation-number {
  color: var(--notation-number-active);
}
.detailed .notation-number {
  opacity: 1;
}
.notation-horizontal-line {
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--notation-line-color);
  top: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.notation-vertical-line {
  position: absolute;
  height: 50%;
  width: 1px;
  top: 25%;
  left: 48%;
  background: var(--notation-line-color);
}
.notation-vertical-line.active {
  background: var(--primary-color-regular);
}
.notation-note-circle {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: var(--text-color);
  margin: 0 auto;
}
.Dun {
  top: 66%;
}
.Gun {
  top: 66%;
}
.do {
  top: 42%;
}
.go {
  top: 42%;
}
.Ta {
  top: 18%;
}
.Pa {
  top: 18%;
}
.Ca {
  top: 18%;
  background: var(--notation-bg-color);
  border: 2px solid var(--text-color);
}
.Ca::after {
  content: "";
  width: 3px;
  height: 3px;
  left: 4px;
  top: 4px;
  position: absolute;
  background: var(--text-color);
  border-radius: 50px;
}
.dos {
  top: 42%;
  background: var(--notation-bg-color);
  border: 2px dotted var(--text-color);
}
.gos {
  top: 42%;
  background: var(--notation-bg-color);
  border: 2px dotted var(--text-color);
}
.xx {
  display: none;
}
.active .Ca {
  background: var(--notation-bg-color);
}
.topbar {
  position: fixed;
  max-width: 900px;
  width: 100%;
  height: 56px;
  z-index: 10;
  background: var(--topbar-color);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
@media (min-width: 640px) {
  .topbar {
    top: 0;
  }
}
@media (max-width: 479px) {
  .topbar {
    display: none;
  }
}
.topbar .btn-topbar {
  padding: 0;
  border: 0;
  border-radius: 100%;
  background: transparent;
}
@media (min-width: 640px) {
  .topbar .btn-topbar {
    float: right;
    right: 0;
  }
}
@media (max-width: 479px) {
  .topbar .btn-topbar {
    height: 64px;
  }
}
.topbar .btn-topbar svg {
  fill: var(--note-color);
}
.topbar .logo {
  display: inline-block;
  position: relative;
}
@media (max-width: 479px) {
  .topbar .logo {
    display: none;
  }
}
.topbar .logo:hover {
  cursor: pointer;
}
.topbar .logo .logotype {
  display: inline-block;
  position: absolute;
}
.topbar .logo .logotype svg {
  fill: var(--text-color);
  width: 137px;
}
@media (max-width: 479px) {
  .topbar .logo .logotype {
    display: none;
  }
}
.topbar .logo .logomark-wrapper {
  display: inline-block;
  top: 0;
  margin: 0;
  position: relative;
}
.topbar .logo .logomark {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4%;
  background: var(--primary-color-regular);
}
@media (min-width: 640px) {
  .topbar .logo .logomark {
    padding: 7px;
    margin: 16px 16px 16px 0;
    width: 46px;
    height: 30px;
    border-radius: 10px;
  }
}
@media (max-width: 639px) {
  .topbar .logo .logomark {
    padding: 7px;
    margin: 16px;
    width: 46px;
    height: 30px;
    border-radius: 10px;
  }
}
.topbar .logo .logomark:hover {
  cursor: pointer;
}
.topbar .logo .Ca {
  border: none;
}
.topbar .logo .Ca::after {
  content: none;
}
@media (max-width: 479px) {
  .slider {
    left: 32px;
    top: -150px;
    padding: 0 !important;
  }
}
.btn-volume {
  float: right;
  right: 0;
  margin-top: 8px;
  border: 0;
  border-radius: 100%;
  background: transparent;
}
@media (max-width: 479px) {
  .btn-volume {
    margin-right: 24px;
  }
}
.btn-volume svg {
  fill: var(--note-color);
}
.btn-bpm {
  margin-top: 8px;
  border: 0;
  border-radius: 100%;
  background: transparent;
}
@media (min-width: 640px) {
  .btn-bpm {
    float: right;
    right: 0;
  }
}
@media (max-width: 479px) {
  .btn-bpm {
    display: none;
    margin-right: 24px;
  }
}
.btn-bpm svg {
  fill: var(--note-color);
}
.sliderButton {
  width: 48px;
  height: 200px;
}
@media (min-width: 640px) {
  .sliderButton {
    float: right;
    right: 0;
  }
}
.btn-rounded {
  border-radius: 100%;
  display: block;
  padding: 0;
}
.logo-big {
  margin-top: 80px;
}
@media (min-width: 640px) {
  .logo-big {
    display: none;
  }
}
.logo-big .logotype {
  display: block;
}
@media (min-width: 640px) {
  .logo-big .logotype {
    margin: 40px auto 0px;
  }
}
@media (max-width: 639px) {
  .logo-big .logotype {
    margin: 20px auto 0px;
    width: 200px;
    height: auto;
  }
}
.logo-big .logotype svg {
  fill: var(--text-color);
}
@media (max-width: 639px) {
  .logo-big .logotype svg {
    width: 200px;
  }
}
.logo-big .logomark {
  text-align: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4%;
  background: var(--primary-color-regular);
}
@media (min-width: 640px) {
  .logo-big .logomark {
    padding: 16px;
    margin: 0 auto;
    width: 168px;
    height: 108px;
    border-radius: 35px;
  }
}
@media (max-width: 639px) {
  .logo-big .logomark {
    padding: 10px;
    margin: 0 auto;
    width: 108px;
    height: 68px;
    border-radius: 20px;
  }
}
.logomark-note {
  width: 100%;
  padding-top: 100%;
  border-radius: 100%;
  -ms-grid-column-align: center;
  justify-self: center;
}
.logomark-note.Dun {
  background: var(--logo-note-color);
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}
.logomark-note.Gun {
  background: var(--logo-note-color);
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}
.logomark-note.Ca {
  position: relative;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
  background: var(--logo-note-color);
  border: none;
}
.logomark-note.Ca::after {
  display: none;
}
@media (min-width: 640px) {
  ::-webkit-scrollbar {
    width: 15px;
    right: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: var(--scrollbar-color);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.btn-volume {
  float: right;
  right: 0;
  margin-top: 8px;
  border: 0;
  border-radius: 100%;
  background: transparent;
}
@media (max-width: 479px) {
  .btn-volume {
    margin-right: 24px;
  }
}
.btn-volume svg {
  fill: var(--note-color);
}
.btn-bpm {
  margin-top: 8px;
  border: 0;
  border-radius: 100%;
  background: transparent;
}
@media (min-width: 640px) {
  .btn-bpm {
    float: right;
    right: 0;
  }
}
@media (max-width: 479px) {
  .btn-bpm {
    display: none;
    margin-right: 24px;
  }
}
.btn-bpm svg {
  fill: var(--note-color);
}
.extra-info {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 8px;
  margin-left: 24px;
  opacity: 0.3;
}
.extra-info * {
  display: inline-block;
  margin-right: 40px;
}
.MuiSlider-root {
  color: var(--primary-color-regular) !important;
}
.MuiButton-root:hover,
.MuiTouchRipple-root {
  color: var(--primary-color-regular) !important;
}
.button-open-settings {
  font-size: 16px;
  background: var(--primary-color-regular);
  color: #fff;
  border: none;
  padding: 16px 24px;
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 2;
  border-radius: 100px;
}
@media (min-width: 640px) {
  .button-open-settings {
    display: none;
  }
}
[data-rsbs-overlay] {
  background: var(--bg-color);
}
.settings-content {
  padding: 0 32px;
}
.settings-content h1 {
  margin: 16px 0 24px 0;
}
.settings-row {
  margin-bottom: 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.2fr 2fr;
  grid-template-columns: 1.2fr 2fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
}
.btn-settings {
  background: var(--notation-bg-color);
  padding: 0 !important;
}
.btn-settings svg {
  fill: var(--note-color);
}
.btn-settings.active {
  background: var --notation-bg-color;
}
.btn-settings.active label {
  color: var(--text-color);
}
